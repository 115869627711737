import api from '@/core/client';
import { StoreDto } from '@/generated/typing';
import { useToken } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useMemo } from 'react';
import { HiMiniDocumentText } from 'react-icons/hi2';
import { IPieData, PieChart } from '../ui/charts/pie-chart/PieChart';
import Title from '../ui/Title';
import Panel from '@/components/ui/Panel';

interface ISubscriptionsCountCardProps {
    startDate?: string;
    endDate?: string;
    activeStore?: StoreDto;
    selectedStoreIds?: string[];
}

const SubscriptionsCountCard = ({
    startDate,
    endDate,
    activeStore,
    selectedStoreIds,
}: ISubscriptionsCountCardProps) => {
    const { data: countSubscriptionsBySalesChannel } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id,
            'countSubscriptionsByDateRange',
            { startDate, endDate, selectedStoreIds },
        ],
        queryFn: () =>
            api.statistics.getSubscriptionsCountByDateRange(activeStore?.id ?? '', {
                from: startDate,
                to: endDate,
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        keepPreviousData: false,
        enabled: !!activeStore?.id && !!selectedStoreIds,
    });

    const [active, pending, voided, refunded, canceled, unknown] = useToken('colors', [
        'green.300',
        'gray.200',
        'orange.200',
        'purple.300',
        'red.400',
        'gray.300',
    ]);
    const chartData = useMemo(() => {
        return (
            countSubscriptionsBySalesChannel?.map((item) => {
                let label = '';
                let color = '';
                switch (item.label) {
                    case 'active':
                        label = t('dashboard:active_subscriptions');
                        color = active;
                        break;
                    case 'pending':
                        label = t('dashboard:inactive_subscriptions');
                        color = pending;
                        break;
                    case 'voided':
                        label = t('dashboard:voided_subscriptions');
                        color = voided;
                        break;
                    case 'refunded':
                        label = t('dashboard:refunded_subscriptions');
                        color = refunded;
                        break;
                    case 'canceled':
                        label = t('dashboard:canceled_subscriptions');
                        color = canceled;
                        break;
                    default:
                        label = t('dashboard:unknown_subscriptions');
                        color = unknown;
                        break;
                }
                return {
                    id: item.label,
                    value: item.value,
                    label,
                    color,
                } as IPieData;
            }) || []
        );
    }, [countSubscriptionsBySalesChannel, active, pending, voided, refunded, canceled, unknown]);

    return (
        <Panel filled>
            <Title IconComponent={HiMiniDocumentText} title={t('dashboard:subscriptions_nb')} />
            <PieChart
                data={chartData}
                margin={{ top: 6, right: 6, bottom: 6, left: 6 }}
                height="200px"
            />
        </Panel>
    );
};

export default SubscriptionsCountCard;
